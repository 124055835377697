<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import moment from 'moment'
  import axios from "axios";

  export default {
    page: {title: "EINSTUDIO-Profile",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader
    },data() {
      return {
        title: "USER PROFILE",
        items: [{text: "Home",href:"#"}, {text: "User Profile",active: true}],
        baseUrl:test.baseUrl,fileUrl:test.fileUrl,fileArray:[],user:[],editUser:{
          name:"",surName:"",userName:"",fullName:"",userTitle:"",mail:"",phone:"",address:"",school:"",classRoom:"",section:"",userImage:"",country:"",city:"",userGroupId:"",explanation:"",status:true,password:""
        }
      };
      },created:function(){
        this.user = JSON.parse(localStorage.getItem('user'));
        this.loadData();
      },methods: {
         addData(){
         let loader = this.$loading.show();
         var formToPost = new FormData(document.getElementById("edit-user-form"));
         formToPost.append("status",this.editUser.status);

         formToPost.append("status",true)
         axios.put(`${this.baseUrl}/User/Set`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`}
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },editImage(){
        let loader = this.$loading.show();
        var formToPost = new FormData(document.getElementById("profile-image-form"));
        formToPost.append("status",true)
        axios.put(`${this.baseUrl}/User/Set`, formToPost, {
        headers: {
          Authorization: `Bearer ${this.user.jwt}`}
        })
        .then((response) => 
        {
        loader.hide();
        this.$swal("",response.data.message, "success");
        this.loadData();
        })
        .catch((error) => 
        {
        loader.hide();
        var html="";
        if(error.response.data.length>0)
        {
          for (let index = 0; index < error.response.data.length; index++) 
          {
            html +="<small>"+ error.response.data[index].message+"</small><hr>";
          }
        }
        else
        {
          html ="<p class='small'>"+ error.response.data.message+"</p>";
        }
        this.$swal("",html,"error");
        });
      },loadData(){
          let url= this.baseUrl+"/User/GetById/"+this.$route.params.id;
          axios.get(url,{
            headers:{ Authorization: `Bearer ${this.user.jwt}`}
          }).then((response)=>{
              this.editUser= response.data;
              console.log(this.editUser)
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },changePassword(){
        let loader = this.$loading.show();
        var formToPost = new FormData(document.getElementById("change-password-form"));
        axios.patch(`${this.baseUrl}/User/ChangePassword`, formToPost, {
        headers: {
          Authorization: `Bearer ${this.user.jwt}`}
        })
        .then((response) => 
        {
        loader.hide();
        this.$swal("",response.data.message, "success");
        this.loadData();
        })
        .catch((error) => 
        {
        loader.hide();
        var html="";
        if(error.response.data.length>0)
        {
          for (let index = 0; index < error.response.data.length; index++) 
          {
            html +="<small>"+ error.response.data[index].message+"</small><hr>";
          }
        }
        else
        {
          html ="<p class='small'>"+ error.response.data.message+"</p>";
        }
        this.$swal("",html,"error");
        });
      },checked(){ 
       let elem=  document.getElementById("isOnlyTeacher");
       this.editUser.status=elem.checked;
       console.log(this.editUser.status)
      },format_date(value)
      {
         if (value) 
         {
           return moment(String(value)).format('MM.DD.YYYY HH:mm')
          }
      },showModal(modalid) {
        this.$root.$emit('bv::show::modal',modalid)
      },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
     }
  };
</script>
<style>
.cover-image{
  width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                  <div>
                    <b-tabs content-class="mt-3" fill>
                      <b-tab title="Profile" active>
                        <div class="row">
                          <div class="col-4">
                                <div class="form-group">
                                  <img :src="(editUser.userImage!=null && editUser.userImage!='')? (fileUrl+'/files/images/users/'+editUser.userImage):(fileUrl+'/files/images/logo-sm-light.png')" class="img-thumbnail border"/>
                                </div> 
                                <form id="profile-image-form" @submit.prevent="editImage()" v-if="user.id==$route.params.id | user.rol=='admin'">
                                  <input type="hidden" v-model="editUser.id" name="id"/>
                                  <label>Change Profile Image</label>
                                  <div class="input-group">
                                    <div class="custom-file">
                                      <input type="file" class="custom-file-input" id="file" name="file" accept=".jpg,.png,.JPG,.PNG,.jpeg,.JPEG" required>
                                      <label class="custom-file-label" for="file">Choose Image</label>
                                    </div>
                                    <div class="input-group-append">
                                      <button class="btn btn-success" type="submit">Save</button>
                                    </div>
                                  </div>
                                </form>
                          </div>
                          <div class="col-8">
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group"><label>Name Surname</label>
                                <p>{{ editUser.fullName }}</p>
                                </div>
                               
                                <div class="form-group"><label>Title</label>
                                  <p>
                                    {{ editUser.title }}
                                  </p>
                        
                                </div>
                                <div class="form-group"><label>Email</label>
                                  <p>
                                    {{ editUser.mail }}
                                  </p>
                                </div>
                                <div class="form-group"><label>Phone</label>
                                  <p>
                                    {{ editUser.phone }}
                                  </p>
                                </div>
                                <div class="form-group"><label>Address</label>
                                  <p>
                                    {{ editUser.address }}
                                  </p>
                                </div>
                                <div class="form-group"><label>Country</label>
                                  <p>
                                    {{ editUser.country }}
                                  </p>
                                </div>
                                <div class="form-group"><label>City</label>
                                  <p>
                                    {{ editUser.city }}
                                  </p>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group"><label>User Group</label>
                                  <p>
                                    {{ editUser.userGroup.name }}
                                  </p>
                        
                                </div>
                                <div class="form-group"><label class="font-bold">School</label>
                                  <p>
                                    {{ editUser.school }}
                                  </p>
                                </div>
                                <div class="form-group"><label>Section</label>
                                  <p>
                                    {{ editUser.section }}
                                  </p>
                                </div>
                                <div class="form-group"><label>Class</label>
                                  <p>
                                    {{ editUser.classRoom }}
                                  </p>
                                </div>
                                <div class="form-group"><label>Create Date</label>
                                  <p>
                                    {{ this.format_date(editUser.createDate) }}
                                  </p>
                                </div>
                                <div class="form-group"><label>Last Up Date</label>
                                  <p>
                                    {{ this.format_date(editUser.upDate) }}
                                  </p>
                                </div>
                                <div class="form-group"><label>Explanation</label>
                                  <p>
                                    {{editUser.explanation}}
                                  </p>
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                      </b-tab>
                      <b-tab title="Change Profile" v-if="user.id==$route.params.id | user.rol=='admin'">
                        <form class="form-horizontal"  @submit.prevent="addData()" id="edit-user-form">
                          <input type="hidden" v-model="editUser.userGroupId" name="userGroupId"/>
                          <input type="hidden" v-model="editUser.id" name="id"/>
                            <div class="row">
                              <div class="col-6">
                                <div class="row form-group">
                                  <div class="col-4">
                                      <label for="example-text-input" class="col-form-label">Title</label>
                                      <input @input="(val) => (editUser.title = editUser.title.toUpperCase())" class="form-control" type="text" v-model="editUser.title" name="title">
                                    </div>
                                    <div class="col-4"> 
                                      <label for="example-text-input" class="col-form-label">Name</label>
                                      <input class="form-control" type="text" v-model="editUser.name" name="name">
                                    </div>
                                    <div class="col-4">
                                      <label for="example-text-input" class="col-form-label">SurName</label>
                                      <input class="form-control" type="text" v-model="editUser.surName" name="surName">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-6">
                                      <label for="example-email-input" class="col-form-label">Email</label>
                                        <input class="form-control" type="email" v-model="editUser.mail" name="mail">
                                    </div>
                                    <div class="col-6">
                                      <label for="example-tel-input" class="col-form-label">Phone</label>
                                      <input class="form-control" type="tel" v-model="editUser.phone" name="phone">
                                    </div>
                                  
                                </div>
                                <div class="row form-group">
                                    <div class="col-6">
                                      <label class="col-form-label">Role</label>
                                        <select class="form-control" name="userGroupId" v-model="editUser.userGroupId"  :disabled="user.rol!='admin'">
                                            <option value="2">Teacher</option>
                                            <option value="3">Student</option>
                                        </select>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-check" v-if="user.rol=='admin'">
                                          <input class="form-check-input" type="checkbox" v-model="editUser.status"  @change="checked()"  value="" id="isOnlyTeacher" >
                                        <label class="form-check-label" for="isOnlyTeacher"> Status? </label>
                                      </div>
                                    </div>
                                  
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="row form-group">
                                    <div class="col-4"> 
                                      <label class="col-form-label">School</label>
                                      <input @input="(val) => (editUser.school = editUser.school.toUpperCase())" class="form-control" type="text" v-model="editUser.school" name="school">
                                    </div>
                                    <div class="col-4">
                                      <label class="col-form-label">classRoom</label>
                                      <input @input="(val) => (editUser.classRoom = editUser.classRoom.toUpperCase())" class="form-control" type="text" v-model="editUser.classRoom" name="classRoom">
                                    </div>
                                    <div class="col-4">
                                      <label class="col-form-label">Section</label>
                                      <input @input="(val) => (editUser.section = editUser.section.toUpperCase())" class="form-control" type="text" v-model="editUser.section" name="section">
                                    </div>
                                </div>
                                <div class="row form-group">
                                  <div class="col-12">
                                      <label class="col-form-label">Address</label>
                                      <input class="form-control" type="text" v-model="editUser.address" name="address">
                                    </div>
                                    <div class="col-6"> 
                                      <label class="col-form-label">Country</label>
                                      <input @input="(val) => (editUser.country = editUser.country.toUpperCase())" class="form-control" type="text" v-model="editUser.country" name="country">
                                    </div>
                                    <div class="col-6">
                                      <label  class="col-form-label">City</label>
                                          <input  @input="(val) => (editUser.city = editUser.city.toUpperCase())" class="form-control" type="text" v-model="editUser.city" name="city">
                                    </div>
                                </div>
                              </div>
                            </div>
                        <button type="submit" class="btn btn-primary">Save Change</button>
                        </form>
                      </b-tab>
                      <b-tab title="Change Password" v-if="user.id==$route.params.id | user.rol=='admin'">
                        <form id="change-password-form" @submit.prevent="changePassword()">
                        <input type="hidden" v-model="editUser.id" name="userId"/>
                         <div class="row">
                          <div class="col-4"></div>
                          <div class="col-4">
                            <div class="form-group">
                              <label><i class="fa fa-info-circle"></i> New User Name (optional)</label>
                             <input type="text" name="newUserName" class="form-control" placeholder="New Username">
                            </div>
                            <div class="form-group">
                              <label><i class="fa fa-info-circle"></i> New Password (*)</label>
                             <input name="newPassword" class="form-control"  type="password" placeholder="Enter password" maxlength="10" minlength="8" max="10" min="8" required>
                            </div>
                            <div class="form-group">
                              <label><i class="fa fa-info-circle"></i> Confirm Password (*)</label>
                              <input name="reNewPassword" class="form-control"  type="password" placeholder="Confirm password" maxlength="10" minlength="8" max="10" min="8" required>
                            </div>
                            <button type="submit" class="btn btn-primary">Save Change</button>
                          </div>
                         </div>
                      
                        </form>
                      </b-tab>
                    </b-tabs>
                  </div>

                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>